<template>
  <div class="InfoWrap com-mouse-default">
    <div class="priceArea">
      <div class="priceArea1 com-flex">
        <div class="com-flex" style="display: flex; align-items: center">
          <div class="title" style="font-weight: 600; font-size: 19px">
            {{ info.cinvCode }}
          </div>
        </div>
      </div>
    </div>
    <div class="goodsDetail">
      <h3
        class="InfoName"
        style="font-size: 17px; font-weight: 500"
        v-if="info.shoppingMallName"
      >
        {{ info.brandName + "-" + info.shoppingMallName }}
      </h3>
      <h3 class="InfoName" style="font-size: 17px; font-weight: 500" v-else>
        {{ info.brandName }}
      </h3>
      <div class="priceArea" style="border-bottom: 1px solid #ededed">
        <div class="priceArea1 com-flex">
          <div
            class="com-flex"
            style="display: flex; align-items: center; margin-bottom: 16px"
          >
            <div class="title" style="font-weight: 500; font-size: 17px">Price:</div>
            <div class="price" style="display: flex">
              <div style="font-size: 17px; font-weight: 500"></div>
              <span
                style="font-size: 17px; font-weight: 500"
                v-if="organizationNames == '迪拜'"
              >
                {{ infoCopy.price.toFixed(2) }}/DHS</span
              >
              <span style="font-size: 17px; font-weight: 500" v-else>
                {{ infoCopy.price.toFixed(2) }}/RMB</span
              >
            </div>
            <!-- <div class="remark" style="margin-left: 20px;">
  
                <i>月销量:768箱</i>
              </div> -->
          </div>
        </div>
      </div>
      <div class="support">
        <div class="supportArea" style="display: flex">
          <div
            class="title"
            style="font-weight: 500; font-size: 17px"
            v-if="lange == '中文'"
          >
            剩余库存：
          </div>
          <div class="title" style="font-weight: 500; font-size: 17px" v-else>
            Stock Avaliable
          </div>
          <div class="fixWidth" style="font-size: 16px; font-weight: 500; width: 60%">
            {{ parseInt(info.availableQuantity) }}/PCS
          </div>
        </div>
      </div>
    </div>

    <!-- 属性列表 -->
    <div class="choose">
      <div class="cartWrap">
        <div class="controls com-flex">
          <div style="border-bottom: 1px solid #ededed">
            <div
              class="title"
              style="font-weight: 500; font-size: 17px"
              v-if="lange == '中文'"
            >
              装箱配比：
            </div>
            <div class="title" style="font-weight: 500; font-size: 17px" v-else>
              Packing Ratio
            </div>
            <div>
              <div style="display: flex; flex-wrap: wrap">
                <div
                  v-for="(item, index) in colorList"
                  :key="index"
                  v-if="item.fpeiBi"
                  style="
                    display: flex;
                    aligin-item: center;
                    flex-direction: column;
                    margin-right: 4%;
                    margin-bottom: 10px;
                    width: 20%;
                  "
                >
                  <el-image
                    style="
                      width: 100%;
                      height: 100%;
                      margin-right: 10px;
                      margin-bottom: 10px;
                    "
                    :src="item.imageUrl"
                    :preview-src-list="colorSumList"
                  >
                  </el-image>
                  <div
                    style="width: 100%; justify-content: center; align-items: center"
                    v-if="!lpButtonShow"
                  >
                    <div class="counters">
                      <div style="font-size: 21px; color: #c1c1c1">-</div>
                      <div style="font-weight: 500">{{ item.fpeiBiCopy }}</div>

                      <div style="font-size: 21px; color: #c1c1c1">+</div>
                    </div>
                  </div>
                  <div
                    style="width: 100%; justify-content: center; align-items: center"
                    v-else
                  >
                    <div class="counters">
                      <div
                        style="font-size: 21px; color: black"
                        @click="decrementItem(item, index)"
                      >
                        -
                      </div>
                      <!-- <div style="font-weight: 500">{{ item.fpeiBiCopy }}</div> -->
                      <div>
                        <input
                          type="number"
                          :maxlength="11"
                          v-model="item.fpeiBiCopy"
                          class="input"
                          @change="fpeiBiCopyChange(item, index)"
                          min="1"
                          placeholder-style="color:#adc9d8;"
                          style="
                            width: 100%;
                            border: none;
                            outline: none;
                            height: 100%;
                            color: #333;
                            font-size: 20px;
                            text-align: center;
                          "
                        />
                      </div>
                      <div
                        style="font-size: 21px; color: black"
                        @click="incrementItem(item, index)"
                      >
                        +
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style="display: flex; align-items: center; margin-bottom: 20px"
              v-if="boxNumberShow"
            >
              <div style="line-height: 40px">购买箱数：</div>
              <div style="width: 80%; justify-content: center">
                <div class="counter">
                  <div @click="decrement" style="font-size: 35px; margin-left: 20px">
                    -
                  </div>
                  <div>
                    <input
                      type="number"
                      :maxlength="11"
                      v-model="buyCount"
                      class="input"
                      min="1"
                      @change="buyCountChange"
                      placeholder-style="color:#adc9d8;"
                      style="
                        border: none;
                        outline: none;
                        height: 100%;
                        color: #333;
                        font-size: 20px;
                        text-align: center;
                      "
                    />
                  </div>
                  <div @click="increment" style="font-size: 35px; margin-right: 20px">
                    +
                  </div>
                </div>
              </div>
            </div>
            <div class="priceArea" v-if="!lpButtonShow">
              <div class="priceArea1 com-flex" style="line-height: 18px">
                <div
                  class="com-flex"
                  style="display: flex; align-items: center; margin-bottom: 10px"
                >
                  <div
                    class="title"
                    style="font-weight: 500; font-size: 17px"
                    v-if="lange == '中文'"
                  >
                    购买数量：
                  </div>
                  <div class="title" style="font-weight: 500; font-size: 17px" v-else>
                    Order Quantity：
                  </div>
                  <div class="price" style="display: flex">
                    <div style="font-size: 17px; font-weight: 500"></div>
                    <span style="font-size: 17px; font-weight: 500">
                      {{ buyCountSum }}/PCS</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="priceArea" v-else>
              <div class="priceArea1 com-flex" style="line-height: 18px">
                <div
                  class="com-flex"
                  style="display: flex; align-items: center; margin-bottom: 10px"
                >
                  <div
                    class="title"
                    style="font-weight: 500; font-size: 17px"
                    v-if="lange == '中文'"
                  >
                    购买数量：
                  </div>
                  <div class="title" style="font-weight: 500; font-size: 17px" v-else>
                    Order Quantity：
                  </div>
                  <div class="price" style="display: flex">
                    <div style="font-size: 17px; font-weight: 500"></div>
                    <span style="font-size: 17px; font-weight: 500">
                      {{ buyCountSum }}/PCS</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="priceArea" v-if="!lpButtonShow">
              <div class="priceArea1 com-flex" style="line-height: 18px">
                <div
                  class="com-flex"
                  style="display: flex; align-items: center; margin-bottom: 10px"
                >
                  <div
                    class="title"
                    style="font-weight: 500; font-size: 17px"
                    v-if="lange == '中文'"
                  >
                    合计体积：
                  </div>
                  <div class="title" style="font-weight: 500; font-size: 17px" v-else>
                    Total Volume：
                  </div>
                  <div class="price" style="display: flex">
                    <div style="font-size: 17px; font-weight: 500"></div>
                    <span style="font-size: 17px; font-weight: 500">
                      {{ ivolumeSum.toFixed(2) }}m³</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="priceArea" v-if="lpButtonShow">
              <div class="priceArea1 com-flex" style="line-height: 18px">
                <div
                  class="com-flex"
                  style="display: flex; align-items: center; margin-bottom: 10px"
                >
                  <div
                    class="title"
                    style="font-weight: 500; font-size: 17px"
                    v-if="lange == '中文'"
                  >
                    合计体积：
                  </div>
                  <div class="title" style="font-weight: 500; font-size: 17px" v-else>
                    Total Volume：
                  </div>
                  <div class="price" style="display: flex">
                    <div style="font-size: 17px; font-weight: 500"></div>
                    <span style="font-size: 17px; font-weight: 500">
                      {{ ivolumeSum.toFixed(2) }}m³</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="priceArea" v-if="!lpButtonShow">
              <div class="priceArea1 com-flex" style="line-height: 18px">
                <div
                  class="com-flex"
                  style="display: flex; align-items: center; margin-bottom: 10px"
                >
                  <div
                    class="title"
                    style="font-weight: 500; font-size: 17px"
                    v-if="lange == '中文'"
                  >
                    总金额：
                  </div>
                  <div class="title" style="font-weight: 500; font-size: 17px" v-else>
                    Total Amount：
                  </div>
                  <div class="price" style="display: flex">
                    <div style="font-size: 17px; font-weight: 500"></div>

                    <span
                      style="font-size: 17px; font-weight: 500"
                      v-if="organizationNames == '迪拜'"
                    >
                      {{ buyCountSumMoney.toFixed(2) }}/DHS</span
                    >
                    <span style="font-size: 17px; font-weight: 500" v-else>
                      {{ buyCountSumMoney.toFixed(2) }}/RMB</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="priceArea" v-if="lpButtonShow">
              <div class="priceArea1 com-flex" style="line-height: 18px">
                <div
                  class="com-flex"
                  style="display: flex; align-items: center; margin-bottom: 10px"
                >
                  <div
                    class="title"
                    style="font-weight: 500; font-size: 17px"
                    v-if="lange == '中文'"
                  >
                    总金额：
                  </div>
                  <div class="title" style="font-weight: 500; font-size: 17px" v-else>
                    Total Amount：
                  </div>
                  <div class="price" style="display: flex">
                    <div style="font-size: 17px; font-weight: 500"></div>
                    <span
                      style="font-size: 17px; font-weight: 500; color: #fb4545"
                      v-if="organizationNames == '迪拜'"
                    >
                      {{ buyCountSumMoney.toFixed(2) }}/DHS</span
                    >
                    <span
                      style="font-size: 17px; font-weight: 500; color: #fb4545"
                      v-else
                    >
                      {{ buyCountSumMoney.toFixed(2) }}/RMB</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div style="width: 100%; justify-content: center; margin-top: 20px">
              <div
                class="counter1"
                style="
                  font-weight: 500;
                  background: black;
                  color: white;
                  text-align: center;
                  justify-content: center;
                "
                @click="addCart()"
              >
                <div v-if="lange == '中文'">加入购物车</div>
                <div v-else>Add To Cart</div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div
            style="
              width: 100%;
              min-height: 40px;
              background: #f8f8f8;
              margin-bottom: 15px;
              line-height: 40px;
              display: flex;
            "
          >
            <div style="margin-left: 20px; margin-right: 50px">
              {{ skuNum10 * 24 }}件/{{ skuNum10 }}箱
            </div>
            <div>
              总价格：<span style="color: #ff4400">￥{{ skuNum10 * 899 }}</span>
            </div>
            <div style="margin-left: 20px">
              体积：<span style="color: #ff4400">{{ skuNum10 * 5 }}m³</span>
            </div>
          </div> -->
        <!-- <div class="add com-mouse-point">
            <a class="addCart buy" @click="addCarts">立即购买</a>
            <a class="addCart" @click="addCart"
              ><span class="iconfont icon-gouwuche-tianchong"></span> 加入购物车</a
            >
          </div> -->
      </div>
    </div>
    <el-dialog
      title="购物车存在订单详情"
      :visible.sync="dialogTableVisible"
      width="1400px"
      append-to-body
    >
      <shopCarts ref="shopCarts"></shopCarts>
    </el-dialog>
    <el-dialog
      title="支付详情"
      :visible.sync="dialogTableVisibles"
      width="550px"
      append-to-body
    >
      <div style="display: flex">
        <div>
          <div style="margin-left: 31px">支付宝支付：</div>
          <img
            src=" https://www.gqedu.org.cn:6868/manage/61b18e454c15444aa5102c76fab0cfc0.png"
          />
        </div>
        <div>
          <div style="margin-left: 31px">微信支付：</div>
          <img
            src=" https://www.gqedu.org.cn:6868/manage/61b18e454c15444aa5102c76fab0cfc0.png"
          />
        </div>
      </div>
      <div style="display: flex; justify-content: center">
        <el-button type="success" @click="dialogTableVisiblea = true"
          >上传支付凭证</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="上传凭证"
      :visible.sync="dialogTableVisiblea"
      width="550px"
      append-to-body
    >
      <el-upload action="#" list-type="picture-card" :auto-upload="false">
        <i slot="default" class="el-icon-plus"></i>
        <div slot="file" slot-scope="{ file }">
          <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
          <span class="el-upload-list__item-actions">
            <span
              class="el-upload-list__item-preview"
              @click="handlePictureCardPreview(file)"
            >
              <i class="el-icon-zoom-in"></i>
            </span>
            <span
              v-if="!disabled"
              class="el-upload-list__item-delete"
              @click="handleDownload(file)"
            >
              <i class="el-icon-download"></i>
            </span>
            <span
              v-if="!disabled"
              class="el-upload-list__item-delete"
              @click="handleRemove(file)"
            >
              <i class="el-icon-delete"></i>
            </span>
          </span>
        </div>
      </el-upload>
    </el-dialog>
    <el-dialog title="下单详情" :visible.sync="dialogTableVisiblep" width="1400px">
      <shopOrder ref="shopCarts"></shopOrder>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="dialogVisiblep"
      width="30%"
      append-to-body
      @close="gobackFather"
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <i class="el-icon-shopping-bag-2" style="font-size: 30px"></i>
        <div style="font-size: 20px" v-if="lange == '中文'">此货品已添加到购物车</div>
        <div style="font-size: 20px" v-else>
          This item has been added to the shopping cart
        </div>

        <div
          style="
            width: 100%;
            justify-content: center;
            margin-top: 20px;
            margin-bottom: 20px;
            display: flex;
          "
        >
          <div
            class="counter1"
            style="
              font-weight: 500;
              background: black;
              color: white;
              text-align: center;
              justify-content: center;
            "
            @click="goToCart()"
          >
            <div>{{ lange == "中文" ? "查看购物车" : "View shopping cart" }}</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getDcBasearchiveCommodityPackingratio } from "@/api/good/index.js";
import {
  addDcShoppingmallShoppingcart,
  getDcShoppingmallShoppingcart,
  editDcShoppingmallShoppingcart,
} from "@/api/cart/index.js";
import { mapGetters } from "vuex";
import shopOrder from "../shopOrder/index.vue";
import shopCarts from "../shopCarts/index.vue";

export default {
  name: "InfoDetail",
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    type: {
      type: Number,
      default: 0,
    },
    cInvCode: {
      type: String,
      default: "",
    },
  },
  components: {
    shopCarts,
    shopOrder,
  },
  computed: {
    ...mapGetters({
      lange: "user/lange",
      priceChangeList: "user/priceChangeList",
    }),
  },
  data() {
    return {
      organizationNames: localStorage.getItem("organizationNames"),
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      srcList: [
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
      ],
      dialogTableVisible: false,
      dialogVisiblep: false,
      buyCount: 0,
      imageUrlList: [],
      dialogTableVisiblea: false,
      colorSumList: [],
      dialogTableVisibles: false,
      dialogTableVisiblep: false,
      lpButtonShow: false,
      skuNum: 2,
      skuNum1: 3,
      skuNum2: 5,
      skuNum3: 7,
      skuNum4: 3,
      skuNum5: 4,
      skuNum10: 1,
      skuDesc: "全场正品 达人推荐 无忧退换",
      colorList: [],
      buyCountSum: 0,
      buyCountSumMoney: 0,
      ivolumeSum: 0,
      boxNumberShow: true,
      infoCopy: {},
      fpeiBiSum: 0,
      cartListHave: [],
      boxMaxBumber: "",
      buyCountMax: "",
      discount: 1,
      addPrice: 0,
      brandName: this.$route.query.brandName,
    };
  },
  created() {
    if (this.priceChangeList.length != 0) {
      if (this.brandName == "CHRISBELLA") {
        let discountLength = this.priceChangeList.filter((item) => {
          return item.brandName == "CHRISBELLA";
        });
        if (discountLength.length != 0) {
          this.discount = discountLength[0].discount / 100;
        }
        let addPriceLength = this.priceChangeList.filter((item) => {
          return item.brandName == "CHRISBELLA";
        });
        if (addPriceLength.length != 0) {
          this.addPrice = addPriceLength[0].priceadjustment;
        }
      } else if (this.brandName == "BAGCO") {
        let discountLength = this.priceChangeList.filter((item) => {
          return item.brandName == "BAGCO";
        });
        if (discountLength.length != 0) {
          this.discount = discountLength[0].discount / 100;
        }
        let addPriceLength = this.priceChangeList.filter((item) => {
          return item.brandName == "BAGCO";
        });
        if (addPriceLength.length != 0) {
          this.addPrice = addPriceLength[0].priceadjustment;
        }
      } else {
        let discountLength = this.priceChangeList.filter((item) => {
          return item.brandName == "SUSEN";
        });
        if (discountLength.length != 0) {
          this.discount = discountLength[0].discount / 100;
        }
        let addPriceLength = this.priceChangeList.filter((item) => {
          return item.brandName == "SUSEN";
        });
        if (addPriceLength.length != 0) {
          this.addPrice = addPriceLength[0].priceadjustment;
        }
      }
    }
    this.infoCopy = this.info;
    if (this.infoCopy.availableQuantity <= 0) {
      this.infoCopy.availableQuantity = 0;
    }
    this.infoCopy.commodityPriceList.forEach(item=>{
      item.priceAfter=(item.priceAfter * 1 + this.addPrice * 1) * this.discount;
    })
    console.log(this.info.packingRatioList);
    this.info.packingRatioList.forEach((itemk) => {
      console.log(itemk.fpeiBi);
      this.fpeiBiSum = this.fpeiBiSum * 1 + itemk.fpeiBi * 1;
    });
    console.log(this.fpeiBiSum);

    this.colorList = JSON.parse(JSON.stringify(this.info.packingRatioList));
    this.colorSumList = [];
    let colorSmallList = [];
    this.colorList.forEach((item) => {
      // this.buyCountSum = this.buyCountSum * 1 + item.fpeiBi * 1;
      console.log(item.iquantity, item.fpeiBi);
      if (item.fpeiBi != 0) {
        colorSmallList.push(Math.floor(item.iquantity / item.fpeiBi));
      }

      item.fpeiBiCopy = item.fpeiBi * 0;
      if (item.imageUrl) {
        item.imageUrl =
          this.$locationUrl +
          "file/abyFile/commodityFile/commodityColorImage/" +
          item.filename;
        this.colorSumList.push(item.imageUrl);
      } else {
        item.imageUrl =
          this.$locationUrl +
          "file/abyFile/commodityFile/commodityColorImage/" +
          item.filename;
        this.colorSumList.push(item.imageUrl);
      }
    });
    this.boxMaxBumber = Math.min(...colorSmallList);
    console.log("colorSmallList this.boxMaxBumber", this.boxMaxBumber);
    // if (this.buyCountSum > this.infoCopy.availableQuantity) {
    //   console.log("库存小于配比数,开启零批模式");
    //   this.colorSumList = [];
    //   this.colorList.forEach((item) => {
    //     item.fpeiBiCopy = 0;
    //     if (item.imageUrl) {
    //       item.imageUrl =
    //         this.$locationUrl +
    //         "file/abyFile/commodityFile/commodityColorImage/" +
    //         item.filename;
    //       this.colorSumList.push(item.imageUrl);
    //     } else {
    //       item.imageUrl =
    //         this.$locationUrl +
    //         "file/abyFile/commodityFile/commodityColorImage/" +
    //         item.filename;
    //       this.colorSumList.push(item.imageUrl);
    //     }
    //   });

    //   let haveBlack = this.colorList.filter((item) => {
    //     return item.pcolorName == "黑色" && item.pcolorName_En == "black";
    //   });
    //   var noBlack = this.colorList.filter((item) => {
    //     return item.pcolorName != "黑色" && item.pcolorName_En != "black";
    //   });
    //   noBlack = noBlack.sort((a, b) => b.fpeiBi - a.fpeiBi);
    //   if (haveBlack.length != 0) {
    //     noBlack.unshift(haveBlack[0]);
    //   }
    //   this.colorList = JSON.parse(JSON.stringify(noBlack));
    //   console.log(haveBlack, noBlack);
    //   this.buyCountSum = 0;
    //   this.infoCopy.price = this.infoCopy.commodityPriceList[0].priceAfter;
    //   console.log("this.infoCopy.price", this.infoCopy.price);
    //   this.buyCountSumMoney = 0;
    //   console.log(this.buyCountSum);
    //   console.log(this.info.packingQuantity);
    //   console.log(this.buyCountSum / this.info.packingQuantity);
    //   this.ivolumeSum = 0;
    //   this.lpButtonShow = true;
    //   this.boxNumberShow = false;
    // } else {
    let haveBlack = this.colorList.filter((item) => {
      return item.pcolorName == "黑色" && item.pcolorName_En == "black";
    });
    var noBlack = this.colorList.filter((item) => {
      return item.pcolorName != "黑色" && item.pcolorName_En != "black";
    });
    noBlack = noBlack.sort((a, b) => b.fpeiBi - a.fpeiBi);
    if (haveBlack.length != 0) {
      noBlack.unshift(haveBlack[0]);
    }
    this.colorList = JSON.parse(JSON.stringify(noBlack));
    console.log(haveBlack, noBlack);
    this.infoCopy.price = this.infoCopy.commodityPriceList[
      this.findMaxIndex(this.infoCopy.commodityPriceList, this.buyCountSum)
    ].priceAfter;
    console.log("this.infoCopy.price", this.infoCopy.price);
    this.buyCountSumMoney = this.buyCountSum * this.infoCopy.price;
    console.log(this.buyCountSum);
    console.log(this.info.packingQuantity);
    console.log(this.buyCountSum / this.info.packingQuantity);

    this.ivolumeSum =
    ((this.buyCountSum / this.info.packingQuantity) * this.info.ivolume/ 1000000) ;
    console.log(this.ivolumeSum);
    if (this.info.sellAttributeList) {
      let haveDictnameLP = this.info.sellAttributeList.filter((item) => {
        return item.dictname == "零批";
      });
      if (haveDictnameLP.length != 0) {
        this.lpButtonShow = true;
      } else {
        this.lpButtonShow = false;
      }
    }
    // }
  },
  methods: {
    init() {
      this.infoCopy = this.info;
      if (this.infoCopy.availableQuantity <= 0) {
        this.infoCopy.availableQuantity = 0;
      }

      console.log(this.info.packingRatioList);
      this.info.packingRatioList.forEach((itemk) => {
        console.log(itemk.fpeiBi);
        this.fpeiBiSum = this.fpeiBiSum * 1 + itemk.fpeiBi * 1;
      });
      console.log(this.fpeiBiSum);

      this.colorList = JSON.parse(JSON.stringify(this.info.packingRatioList));
      this.colorSumList = [];
      let colorSmallList = [];
      this.colorList.forEach((item) => {
        this.buyCountSum = this.buyCountSum * 1 + item.fpeiBi * 1;
        console.log(item.iquantity, item.fpeiBi);
        if (item.fpeiBi != 0) {
        colorSmallList.push(Math.floor(item.iquantity / item.fpeiBi));
      }

        item.fpeiBiCopy = item.fpeiBi * 1;
        if (item.imageUrl) {
          item.imageUrl =
            this.$locationUrl +
            "file/abyFile/commodityFile/commodityColorImage/" +
            item.filename;
          this.colorSumList.push(item.imageUrl);
        } else {
          item.imageUrl =
            this.$locationUrl +
            "file/abyFile/commodityFile/commodityColorImage/" +
            item.filename;
          this.colorSumList.push(item.imageUrl);
        }
      });
      this.boxMaxBumber = Math.min(...colorSmallList);
      console.log("colorSmallList this.boxMaxBumber", this.boxMaxBumber);
      // if (this.buyCountSum > this.infoCopy.availableQuantity) {
      //   console.log("库存小于配比数,开启零批模式");
      //   this.colorSumList = [];
      //   this.colorList.forEach((item) => {
      //     item.fpeiBiCopy = 0;
      //     if (item.imageUrl) {
      //       item.imageUrl =
      //         this.$locationUrl +
      //         "file/abyFile/commodityFile/commodityColorImage/" +
      //         item.filename;
      //       this.colorSumList.push(item.imageUrl);
      //     } else {
      //       item.imageUrl =
      //         this.$locationUrl +
      //         "file/abyFile/commodityFile/commodityColorImage/" +
      //         item.filename;
      //       this.colorSumList.push(item.imageUrl);
      //     }
      //   });

      //   let haveBlack = this.colorList.filter((item) => {
      //     return item.pcolorName == "黑色" && item.pcolorName_En == "black";
      //   });
      //   var noBlack = this.colorList.filter((item) => {
      //     return item.pcolorName != "黑色" && item.pcolorName_En != "black";
      //   });
      //   noBlack = noBlack.sort((a, b) => b.fpeiBi - a.fpeiBi);
      //   if (haveBlack.length != 0) {
      //     noBlack.unshift(haveBlack[0]);
      //   }
      //   this.colorList = JSON.parse(JSON.stringify(noBlack));
      //   console.log(haveBlack, noBlack);
      //   this.buyCountSum = 0;
      //   this.infoCopy.price = this.infoCopy.commodityPriceList[0].priceAfter;
      //   console.log("this.infoCopy.price", this.infoCopy.price);
      //   this.buyCountSumMoney = 0;
      //   console.log(this.buyCountSum);
      //   console.log(this.info.packingQuantity);
      //   console.log(this.buyCountSum / this.info.packingQuantity);
      //   this.ivolumeSum = 0;
      //   this.lpButtonShow = true;
      //   this.boxNumberShow = false;
      // } else {
      let haveBlack = this.colorList.filter((item) => {
        return item.pcolorName == "黑色" && item.pcolorName_En == "black";
      });
      var noBlack = this.colorList.filter((item) => {
        return item.pcolorName != "黑色" && item.pcolorName_En != "black";
      });
      noBlack = noBlack.sort((a, b) => b.fpeiBi - a.fpeiBi);
      if (haveBlack.length != 0) {
        noBlack.unshift(haveBlack[0]);
      }
      this.colorList = JSON.parse(JSON.stringify(noBlack));
      console.log(haveBlack, noBlack);
      this.infoCopy.price = this.infoCopy.commodityPriceList[
        this.findMaxIndex(this.infoCopy.commodityPriceList, this.buyCountSum)
      ].priceAfter;
      console.log("this.infoCopy.price", this.infoCopy.price);
      this.buyCountSumMoney = this.buyCountSum * this.infoCopy.price;
      console.log(this.buyCountSum);
      console.log(this.info.packingQuantity);
      console.log(this.buyCountSum / this.info.packingQuantity);

      this.ivolumeSum =
      ((this.buyCountSum / this.info.packingQuantity) * this.info.ivolume/ 1000000) ;
      console.log(this.ivolumeSum);
      if (this.info.sellAttributeList) {
        let haveDictnameLP = this.info.sellAttributeList.filter((item) => {
          return item.dictname == "零批";
        });
        if (haveDictnameLP.length != 0) {
          this.lpButtonShow = true;
        } else {
          this.lpButtonShow = false;
        }
      }
      // }
    },
    fpeiBiCopyChange(item, index) {
      this.buyCountSum = 0;

      this.buyCountSumMoney = 0;
      this.$set(this.colorList[index], "fpeiBiCopy", item.fpeiBiCopy);
      console.log(this.colorList[index].fpeiBiCopy);

      this.colorList.forEach((item) => {
        // item.fpeiBiCopy = item.fpeiBi * this.buyCount;
        this.buyCountSum = this.buyCountSum * 1 + item.fpeiBiCopy * 1;
      });
      if (this.buyCountSum > this.info.availableQuantity) {
        this.$notify({
          title: this.lange == "中文" ? "错误操作" : "Error",
          message:
            this.lange == "中文"
              ? "购买数量不能大于库存数"
              : "Stock is not available, please reduce your order quantity",
          type: "error",
          duration: 0,
          offset: 100,
        });
        this.buyCountSum = 0;

        this.buyCountSumMoney = 0;

        this.colorList.forEach((item) => {
          // item.fpeiBiCopy = item.fpeiBi * this.buyCount;
          this.buyCountSum = this.buyCountSum * 1 + item.fpeiBiCopy * 1;
        });
        let number = this.buyCountSum - this.info.availableQuantity;
        this.$set(this.colorList[index], "fpeiBiCopy", item.fpeiBiCopy - number);
        this.buyCountSum = 0;
        console.log(this.colorList[index].fpeiBiCopy);
        this.colorList.forEach((item) => {
          // item.fpeiBiCopy = item.fpeiBi * this.buyCount;
          this.buyCountSum = this.buyCountSum * 1 + item.fpeiBiCopy * 1;
        });
        this.infoCopy.price = this.infoCopy.commodityPriceList[
          this.findMaxIndex(this.infoCopy.commodityPriceList, this.buyCountSum)
        ].priceAfter;
        this.buyCountSumMoney = this.buyCountSum * this.infoCopy.price;
        this.ivolumeSum =
      ((this.buyCountSum / this.info.packingQuantity) * this.info.ivolume/ 1000000) ;
        console.log(this.ivolumeSum);
        this.buyCount = 0;
        console.log(this.fpeiBiSum);
        this.$forceUpdate();
      } else {
        if (this.colorList[index].fpeiBiCopy > this.colorList[index].iquantity) {
          this.$notify({
            title: this.lange == "中文" ? "错误操作" : "Error",
            message:
              this.lange == "中文"
                ? "商品数量不能大于商品库存"
                : "The quantity of goods cannot exceed the inventory of goods",
            type: "error",
            duration: 0,
            offset: 100,
          });
          this.buyCountSum = 0;
          this.$set(this.colorList[index], "fpeiBiCopy", this.colorList[index].iquantity);
          this.colorList.forEach((item) => {
            // item.fpeiBiCopy = item.fpeiBi * this.buyCount;
            this.buyCountSum = this.buyCountSum * 1 + item.fpeiBiCopy * 1;
          });
          this.infoCopy.price = this.infoCopy.commodityPriceList[
            this.findMaxIndex(this.infoCopy.commodityPriceList, this.buyCountSum)
          ].priceAfter;
          this.buyCountSumMoney = this.buyCountSum * this.infoCopy.price;
          this.ivolumeSum =
      ((this.buyCountSum / this.info.packingQuantity) * this.info.ivolume/ 1000000) ;
          console.log(this.ivolumeSum);
          this.buyCount = 0;
          console.log(this.fpeiBiSum);
        } else {
          this.infoCopy.price = this.infoCopy.commodityPriceList[
            this.findMaxIndex(this.infoCopy.commodityPriceList, this.buyCountSum)
          ].priceAfter;
          this.buyCountSumMoney = this.buyCountSum * this.infoCopy.price;
          this.ivolumeSum =
          ((this.buyCountSum / this.info.packingQuantity) * this.info.ivolume/ 1000000) ;
          console.log(this.ivolumeSum);
          this.buyCount = 0;
          console.log(this.fpeiBiSum);
        }
        this.$forceUpdate();
      }
      console.log(this.buyCountSum);
    },
    buyCountChange() {
      this.init();
      if (this.infoCopy.availableQuantity % this.infoCopy.packingQuantity == 0) {
        this.buyCountMax =
          this.infoCopy.availableQuantity / this.infoCopy.packingQuantity;
      } else {
        this.buyCountMax =
          Math.floor(
            (this.buyCountMax =
              this.infoCopy.availableQuantity / this.infoCopy.packingQuantity)
          ) *
            1 +
          1;
      }
      console.log("最大后购买箱数", this.buyCountMax);
      this.buyCountSum = 0;
      this.buyCountSumMoney = 0;
      if (this.buyCount < this.buyCountMax) {
        if (this.buyCount > this.boxMaxBumber) {
          this.buyCountSum = 0;
          let fpeiBiPeiBiNumberCount = 0;

          let gapBox = this.buyCount - this.boxMaxBumber; //输入值与最大装箱配比之间的差值 1
          this.colorList.forEach((item) => {
            console.log(item.fpeiBiCopy);
            item.fpeiBiPeiBiNumber = 0;
            item.fpeiBiCopy = item.fpeiBiCopy * 1 * this.boxMaxBumber * 1;
            item.fpeiBiPeiBiNumber = item.iquantity - item.fpeiBiCopy; //计算不成配比箱数各个颜色的剩余库存
            console.log(item.fpeiBiPeiBiNumber);

            fpeiBiPeiBiNumberCount =
              item.fpeiBiPeiBiNumber * 1 + fpeiBiPeiBiNumberCount * 1; //计算不成配比箱数各个颜色的剩余库存之和
          });

          console.log("fpeiBiPeiBiNumberCount", fpeiBiPeiBiNumberCount);
          if (fpeiBiPeiBiNumberCount > this.info.packingQuantity) {
            //不成配比箱数各个颜色的剩余库存之和大于配比数时
            console.log("//不成配比箱数各个颜色的剩余库存之和大于配比数时");
            let fpeiBiCopySumNumber = 0;
            this.colorList.forEach((item) => {
              item.fpeiBiPeiBiNumberLv = (
                item.fpeiBiPeiBiNumber / fpeiBiPeiBiNumberCount
              ).toFixed(2); //计算不成配比箱数各个颜色的新配比比率
              fpeiBiCopySumNumber =
                Math.round(
                  this.info.packingQuantity * item.fpeiBiPeiBiNumberLv * gapBox
                ) + fpeiBiCopySumNumber; //按照不成配比箱数各个颜色的新配比比率重新配比装箱
              console.log(
                "重新配比各个颜色数数量",
                item.pcolorName,
                Math.round(this.info.packingQuantity * item.fpeiBiPeiBiNumberLv)
              );
              item.fpeiBiCopy =
                item.fpeiBiCopy +
                Math.round(this.info.packingQuantity * item.fpeiBiPeiBiNumberLv * gapBox);
              console.log("配比数量", item.fpeiBiCopy);
            });
            console.log(
              "//按照不成配比箱数各个颜色的新配比比率重新配比装箱",
              fpeiBiCopySumNumber
            );
            if (fpeiBiCopySumNumber < this.info.packingQuantity * gapBox) {
              //重新配比装箱数小于系统配比装箱数
              console.log("配比计算数小于配比数");
              const maxElement = this.colorList.reduce((prev, current) => {
                return current.fpeiBiPeiBiNumber > prev.fpeiBiPeiBiNumber
                  ? current
                  : prev;
              });
              console.log("库存最多的配比是", maxElement.pcolorName, maxElement);
              for (let i = 0; i < this.colorList.length; i++) {
                if (
                  this.colorList[i].fpeiBiPeiBiNumber === maxElement.fpeiBiPeiBiNumber
                ) {
                  this.colorList[i].fpeiBiCopy =
                    this.colorList[i].fpeiBiCopy * 1 + 1 * gapBox; // 只给第一个符合条件的元素加1
                  console.log("+1的配比数量是", this.colorList[i].fpeiBiCopy);
                  break; // 找到后退出循环
                }
              }
            } else if (fpeiBiCopySumNumber > this.info.packingQuantity * gapBox) {
              const maxElement = this.colorList.reduce((prev, current) => {
                return current.fpeiBiPeiBiNumber > prev.fpeiBiPeiBiNumber
                  ? current
                  : prev;
              });
              console.log("库存最多的配比是", maxElement.pcolorName, maxElement);
              for (let i = 0; i < this.colorList.length; i++) {
                if (
                  this.colorList[i].fpeiBiPeiBiNumber === maxElement.fpeiBiPeiBiNumber
                ) {
                  this.colorList[i].fpeiBiCopy =
                    this.colorList[i].fpeiBiCopy * 1 - 1 * gapBox; // 只给第一个符合条件的元素加1
                  console.log("+1的配比数量是", this.colorList[i].fpeiBiCopy);
                  break; // 找到后退出循环
                }
              }
            }

            // this.buyCount = this.buyCount + 1;

            this.buyCountSum = this.buyCount * this.info.packingQuantity;
            console.log("最大可买箱数", this.buyCountMax);
          } else {
            console.log(
              "数量剩余数小于配比装箱数--------------------------------------------------------"
            );

            this.buyCount = this.buyCountMax;
            this.colorList.forEach((item) => {
              item.fpeiBiCopy = item.iquantity;
              this.buyCountSum = this.buyCountSum * 1 + item.fpeiBiCopy * 1;
            });
          }
        } else if (this.infoCopy.availableQuantity == 0) {
          this.$notify({
            title: this.lange == "中文" ? "错误操作" : "Error",
            message:
              this.lange == "中文"
                ? "库存不足无法修改"
                : "Insufficient inventory cannot be modified",
            type: "error",
            duration: 0,
            offset: 100,
          });
        } else {
          this.colorList.forEach((item) => {
            item.fpeiBiCopy = item.fpeiBi * this.buyCount;
            this.buyCountSum = this.buyCountSum * 1 + item.fpeiBiCopy * 1;
          });
          console.log(this.buyCountSum);
        }
      } else {
        this.buyCount = this.buyCountMax;
        this.colorList.forEach((item) => {
          item.fpeiBiCopy = item.iquantity;
          this.buyCountSum = this.buyCountSum * 1 + item.fpeiBiCopy * 1;
        });
      }
      this.infoCopy.price = this.infoCopy.commodityPriceList[
        this.findMaxIndex(this.infoCopy.commodityPriceList, this.buyCountSum)
      ].priceAfter;
      this.buyCountSumMoney = this.buyCountSum * this.infoCopy.price;

      this.ivolumeSum =
      ((this.buyCountSum / this.info.packingQuantity) * this.info.ivolume/ 1000000) ;

      console.log(this.ivolumeSum);
    },
    gobackFather() {
      console.log(1);

      this.$emit("backItem", "");
    },
    findMaxIndex(arr, num) {
      let maxIndex = -1;

      for (let i = 0; i < arr.length; i++) {
        if (num >= arr[i].startNum) {
          if (maxIndex === -1 || arr[i].startNum > arr[maxIndex].startNum) {
            maxIndex = i;
          }
        }
      }
      if (maxIndex == -1) {
        maxIndex = 0;
      }
      console.log("maxIndex", maxIndex);
      return maxIndex;
    },
    goToCart() {
      window.open(this.$locationViewUrl + "#/goods/goodsCart?brandName="+this.brandName, "_blank");
    },
    decrement() {
      this.buyCountSum = 0;
      this.buyCountSumMoney = 0;
      if (this.buyCount <= 1) {
        this.buyCount = 1;
      } else {
        this.buyCount--;
      }

      this.init();
      if (this.infoCopy.availableQuantity % this.infoCopy.packingQuantity == 0) {
        this.buyCountMax =
          this.infoCopy.availableQuantity / this.infoCopy.packingQuantity;
      } else {
        this.buyCountMax =
          Math.floor(
            (this.buyCountMax =
              this.infoCopy.availableQuantity / this.infoCopy.packingQuantity)
          ) *
            1 +
          1;
      }
      console.log("最大后购买箱数", this.buyCountMax);
      this.buyCountSum = 0;
      this.buyCountSumMoney = 0;
      if (this.buyCount < this.buyCountMax) {
        if (this.buyCount > this.boxMaxBumber) {
          this.buyCountSum = 0;
          let fpeiBiPeiBiNumberCount = 0;

          let gapBox = this.buyCount - this.boxMaxBumber; //输入值与最大装箱配比之间的差值 1
          this.colorList.forEach((item) => {
            console.log(item.fpeiBiCopy);
            item.fpeiBiPeiBiNumber = 0;
            item.fpeiBiCopy = item.fpeiBiCopy * 1 * this.boxMaxBumber * 1;
            item.fpeiBiPeiBiNumber = item.iquantity - item.fpeiBiCopy; //计算不成配比箱数各个颜色的剩余库存
            console.log(item.fpeiBiPeiBiNumber);

            fpeiBiPeiBiNumberCount =
              item.fpeiBiPeiBiNumber * 1 + fpeiBiPeiBiNumberCount * 1; //计算不成配比箱数各个颜色的剩余库存之和
          });

          console.log("fpeiBiPeiBiNumberCount", fpeiBiPeiBiNumberCount);
          if (fpeiBiPeiBiNumberCount > this.info.packingQuantity) {
            //不成配比箱数各个颜色的剩余库存之和大于配比数时
            console.log("//不成配比箱数各个颜色的剩余库存之和大于配比数时");
            let fpeiBiCopySumNumber = 0;
            this.colorList.forEach((item) => {
              item.fpeiBiPeiBiNumberLv = (
                item.fpeiBiPeiBiNumber / fpeiBiPeiBiNumberCount
              ).toFixed(2); //计算不成配比箱数各个颜色的新配比比率
              fpeiBiCopySumNumber =
                Math.round(
                  this.info.packingQuantity * item.fpeiBiPeiBiNumberLv * gapBox
                ) + fpeiBiCopySumNumber; //按照不成配比箱数各个颜色的新配比比率重新配比装箱
              console.log(
                "重新配比各个颜色数数量",
                item.pcolorName,
                Math.round(this.info.packingQuantity * item.fpeiBiPeiBiNumberLv)
              );
              item.fpeiBiCopy =
                item.fpeiBiCopy +
                Math.round(this.info.packingQuantity * item.fpeiBiPeiBiNumberLv * gapBox);
              console.log("配比数量", item.fpeiBiCopy);
            });
            console.log(
              "//按照不成配比箱数各个颜色的新配比比率重新配比装箱",
              fpeiBiCopySumNumber
            );
            if (fpeiBiCopySumNumber < this.info.packingQuantity * gapBox) {
              //重新配比装箱数小于系统配比装箱数
              console.log("配比计算数小于配比数");
              const maxElement = this.colorList.reduce((prev, current) => {
                return current.fpeiBiPeiBiNumber > prev.fpeiBiPeiBiNumber
                  ? current
                  : prev;
              });
              console.log("库存最多的配比是", maxElement.pcolorName, maxElement);
              for (let i = 0; i < this.colorList.length; i++) {
                if (
                  this.colorList[i].fpeiBiPeiBiNumber === maxElement.fpeiBiPeiBiNumber
                ) {
                  this.colorList[i].fpeiBiCopy =
                    this.colorList[i].fpeiBiCopy * 1 + 1 * gapBox; // 只给第一个符合条件的元素加1
                  console.log("+1的配比数量是", this.colorList[i].fpeiBiCopy);
                  break; // 找到后退出循环
                }
              }
            } else if (fpeiBiCopySumNumber > this.info.packingQuantity * gapBox) {
              const maxElement = this.colorList.reduce((prev, current) => {
                return current.fpeiBiPeiBiNumber > prev.fpeiBiPeiBiNumber
                  ? current
                  : prev;
              });
              console.log("库存最多的配比是", maxElement.pcolorName, maxElement);
              for (let i = 0; i < this.colorList.length; i++) {
                if (
                  this.colorList[i].fpeiBiPeiBiNumber === maxElement.fpeiBiPeiBiNumber
                ) {
                  this.colorList[i].fpeiBiCopy =
                    this.colorList[i].fpeiBiCopy * 1 - 1 * gapBox; // 只给第一个符合条件的元素加1
                  console.log("+1的配比数量是", this.colorList[i].fpeiBiCopy);
                  break; // 找到后退出循环
                }
              }
            }

            // this.buyCount = this.buyCount + 1;

            this.buyCountSum = this.buyCount * this.info.packingQuantity;
            console.log("最大可买箱数", this.buyCountMax);
          } else {
            console.log(
              "数量剩余数小于配比装箱数--------------------------------------------------------"
            );

            this.buyCount = this.buyCountMax;
            this.colorList.forEach((item) => {
              item.fpeiBiCopy = item.iquantity;
              this.buyCountSum = this.buyCountSum * 1 + item.fpeiBiCopy * 1;
            });
          }
        } else if (this.infoCopy.availableQuantity == 0) {
          this.$notify({
            title: this.lange == "中文" ? "错误操作" : "Error",
            message:
              this.lange == "中文"
                ? "库存不足无法修改"
                : "Insufficient inventory cannot be modified",
            type: "error",
            duration: 0,
            offset: 100,
          });
        } else {
          this.colorList.forEach((item) => {
            item.fpeiBiCopy = item.fpeiBi * this.buyCount;
            this.buyCountSum = this.buyCountSum * 1 + item.fpeiBiCopy * 1;
          });
          console.log(this.buyCountSum);
        }
      } else {
        this.buyCount = this.buyCountMax;
        this.colorList.forEach((item) => {
          item.fpeiBiCopy = item.iquantity;
          this.buyCountSum = this.buyCountSum * 1 + item.fpeiBiCopy * 1;
        });
      }
      this.infoCopy.price = this.infoCopy.commodityPriceList[
        this.findMaxIndex(this.infoCopy.commodityPriceList, this.buyCountSum)
      ].priceAfter;
      this.buyCountSumMoney = this.buyCountSum * this.infoCopy.price;

      this.ivolumeSum =
      ((this.buyCountSum / this.info.packingQuantity) * this.info.ivolume/ 1000000) ;

      console.log(this.ivolumeSum);
    },
    increment() {
      this.buyCountSum = 0;
      this.buyCountSumMoney = 0;
      this.buyCount++;
      if (this.infoCopy.availableQuantity % this.infoCopy.packingQuantity == 0) {
        this.buyCountMax =
          this.infoCopy.availableQuantity / this.infoCopy.packingQuantity;
      } else {
        this.buyCountMax =
          Math.floor(
            (this.buyCountMax =
              this.infoCopy.availableQuantity / this.infoCopy.packingQuantity)
          ) *
            1 +
          1;
      }
      if (this.buyCount > this.boxMaxBumber) {
        this.buyCountSum = 0;
        let fpeiBiPeiBiNumberCount = 0;

        this.colorList.forEach((item) => {
          item.fpeiBiPeiBiNumber = item.iquantity - item.fpeiBiCopy; //计算不成配比箱数各个颜色的剩余库存
          console.log(item.fpeiBiPeiBiNumber);

          fpeiBiPeiBiNumberCount =
            item.fpeiBiPeiBiNumber * 1 + fpeiBiPeiBiNumberCount * 1; //计算不成配比箱数各个颜色的剩余库存之和
        });

        console.log("fpeiBiPeiBiNumberCount", fpeiBiPeiBiNumberCount);
        if (fpeiBiPeiBiNumberCount > this.info.packingQuantity) {
          //不成配比箱数各个颜色的剩余库存之和大于配比数时
          console.log("//不成配比箱数各个颜色的剩余库存之和大于配比数时");
          let fpeiBiCopySumNumber = 0;
          this.colorList.forEach((item) => {
            item.fpeiBiPeiBiNumberLv = (
              item.fpeiBiPeiBiNumber / fpeiBiPeiBiNumberCount
            ).toFixed(2); //计算不成配比箱数各个颜色的新配比比率
            fpeiBiCopySumNumber =
              Math.round(this.info.packingQuantity * item.fpeiBiPeiBiNumberLv) +
              fpeiBiCopySumNumber; //按照不成配比箱数各个颜色的新配比比率重新配比装箱
            console.log(
              "重新配比各个颜色数数量",
              item.pcolorName,
              Math.round(this.info.packingQuantity * item.fpeiBiPeiBiNumberLv)
            );
            item.fpeiBiCopy =
              item.fpeiBiCopy +
              Math.round(this.info.packingQuantity * item.fpeiBiPeiBiNumberLv);
            console.log("配比数量", item.fpeiBiCopy);
          });
          console.log(
            "//按照不成配比箱数各个颜色的新配比比率重新配比装箱",
            fpeiBiCopySumNumber
          );
          if (fpeiBiCopySumNumber < this.info.packingQuantity) {
            //重新配比装箱数小于系统配比装箱数
            console.log("配比计算数小于配比数");
            const maxElement = this.colorList.reduce((prev, current) => {
              return current.fpeiBiPeiBiNumber > prev.fpeiBiPeiBiNumber ? current : prev;
            });
            console.log("库存最多的配比是", maxElement.pcolorName, maxElement);
            for (let i = 0; i < this.colorList.length; i++) {
              if (this.colorList[i].fpeiBiPeiBiNumber === maxElement.fpeiBiPeiBiNumber) {
                this.colorList[i].fpeiBiCopy = this.colorList[i].fpeiBiCopy * 1 + 1; // 只给第一个符合条件的元素加1
                console.log("+1的配比数量是", this.colorList[i].fpeiBiCopy);
                break; // 找到后退出循环
              }
            }
          } else if (fpeiBiCopySumNumber > this.info.packingQuantity) {
            const maxElement = this.colorList.reduce((prev, current) => {
              return current.fpeiBiPeiBiNumber > prev.fpeiBiPeiBiNumber ? current : prev;
            });
            console.log("库存最多的配比是", maxElement.pcolorName, maxElement);
            for (let i = 0; i < this.colorList.length; i++) {
              if (this.colorList[i].fpeiBiPeiBiNumber === maxElement.fpeiBiPeiBiNumber) {
                this.colorList[i].fpeiBiCopy = this.colorList[i].fpeiBiCopy * 1 - 1; // 只给第一个符合条件的元素加1
                console.log("+1的配比数量是", this.colorList[i].fpeiBiCopy);
                break; // 找到后退出循环
              }
            }
          }

          // this.buyCount = this.buyCount + 1;
          // this.buyCountMax = this.buyCount + 1;
          this.buyCountSum = this.buyCount * this.info.packingQuantity;
          console.log("最大可买箱数", this.buyCountMax);
        } else {
          console.log(
            "数量剩余数小于配比装箱数--------------------------------------------------------"
          );

          if (this.buyCountMax < this.buyCount) {
            this.buyCount = this.buyCount - 1;
            this.colorList.forEach((item) => {
              item.fpeiBiCopy = item.fpeiBiCopy * 1 + item.fpeiBiPeiBiNumber * 1;
              this.buyCountSum = this.buyCountSum * 1 + item.fpeiBiCopy * 1;
            });
          } else {
            this.colorList.forEach((item) => {
              item.fpeiBiCopy = item.fpeiBiCopy * 1 + item.fpeiBiPeiBiNumber * 1;
              this.buyCountSum = this.buyCountSum * 1 + item.fpeiBiCopy * 1;
            });
          }
        }
      } else if (this.infoCopy.availableQuantity == 0) {
        this.$notify({
          title: this.lange == "中文" ? "错误操作" : "Error",
          message:
            this.lange == "中文"
              ? "库存不足无法修改"
              : "Insufficient inventory cannot be modified",
          type: "error",
          duration: 0,
          offset: 100,
        });
      } else {
        this.colorList.forEach((item) => {
          item.fpeiBiCopy = item.fpeiBi * this.buyCount;
          this.buyCountSum = this.buyCountSum * 1 + item.fpeiBiCopy * 1;
        });
        console.log(this.buyCountSum);
      }

      this.infoCopy.price = this.infoCopy.commodityPriceList[
        this.findMaxIndex(this.infoCopy.commodityPriceList, this.buyCountSum)
      ].priceAfter;
      this.buyCountSumMoney = this.buyCountSum * this.infoCopy.price;

      this.ivolumeSum =
      ((this.buyCountSum / this.info.packingQuantity) * this.info.ivolume/ 1000000) ;

      console.log(this.ivolumeSum);
    },
    decrementItem(item, index) {
      console.log(item);
      this.buyCountSum = 0;
      this.buyCountSumMoney = 0;
      let fpeiBiSum = 0;
      if (item.fpeiBiCopy <= 1) {
        this.$set(this.colorList[index], "fpeiBiCopy", 1);
        console.log(this.colorList[index].fpeiBiCopy);

        this.colorList.forEach((item) => {
          item.fpeiBiCopy = item.fpeiBi * this.buyCount;
          this.buyCountSum = this.buyCountSum * 1 + item.fpeiBiCopy * 1;
        });
        console.log(this.buyCountSum);
        this.infoCopy.price = this.infoCopy.commodityPriceList[
          this.findMaxIndex(this.infoCopy.commodityPriceList, this.buyCountSum)
        ].priceAfter;
        this.buyCountSumMoney = this.buyCountSum * this.infoCopy.price;

        this.ivolumeSum =
        ((this.buyCountSum / this.info.packingQuantity) * this.info.ivolume/ 1000000) ;
        console.log(this.ivolumeSum);
        this.$forceUpdate();
        return;
      }
      this.$set(this.colorList[index], "fpeiBiCopy", item.fpeiBiCopy - 1);
      console.log(this.colorList[index].fpeiBiCopy);
      this.colorList.forEach((item) => {
        this.buyCountSum = this.buyCountSum * 1 + item.fpeiBiCopy * 1;
        this.infoCopy.price = this.infoCopy.commodityPriceList[
          this.findMaxIndex(this.infoCopy.commodityPriceList, this.buyCountSum)
        ].priceAfter;
        this.buyCountSumMoney =
          this.buyCountSumMoney * 1 + item.fpeiBiCopy * this.infoCopy.price;
      });
      console.log(this.fpeiBiSum);
      this.ivolumeSum =
      ((this.buyCountSum / this.info.packingQuantity) * this.info.ivolume/ 1000000) ;
      console.log(this.ivolumeSum);
      this.buyCount = 0;
      this.$forceUpdate();
    },
    incrementItem(item, index) {
      this.buyCountSum = 0;

      this.buyCountSumMoney = 0;
      this.$set(this.colorList[index], "fpeiBiCopy", item.fpeiBiCopy + 1);
      console.log(this.colorList[index].fpeiBiCopy);

      this.colorList.forEach((item) => {
        // item.fpeiBiCopy = item.fpeiBi * this.buyCount;
        this.buyCountSum = this.buyCountSum * 1 + item.fpeiBiCopy * 1;
      });
      if (this.buyCountSum > this.info.availableQuantity) {
        this.$notify({
          title: this.lange == "中文" ? "错误操作" : "Error",
          message:
            this.lange == "中文"
              ? "购买数量不能大于库存数"
              : "Stock is not available, please reduce your order quantity",
          type: "error",
          duration: 0,
          offset: 100,
        });
        this.buyCountSum = 0;

        this.buyCountSumMoney = 0;
        this.$set(this.colorList[index], "fpeiBiCopy", item.fpeiBiCopy - 1);
        console.log(this.colorList[index].fpeiBiCopy);

        this.colorList.forEach((item) => {
          // item.fpeiBiCopy = item.fpeiBi * this.buyCount;
          this.buyCountSum = this.buyCountSum * 1 + item.fpeiBiCopy * 1;
        });
        this.infoCopy.price = this.infoCopy.commodityPriceList[
          this.findMaxIndex(this.infoCopy.commodityPriceList, this.buyCountSum)
        ].priceAfter;
        this.buyCountSumMoney = this.buyCountSum * this.infoCopy.price;
        this.ivolumeSum =
        ((this.buyCountSum / this.info.packingQuantity) * this.info.ivolume/ 1000000) ;
        console.log(this.ivolumeSum);
        this.buyCount = 0;
        console.log(this.fpeiBiSum);
        this.$forceUpdate();
      } else {
        if (this.colorList[index].fpeiBiCopy > this.colorList[index].iquantity) {
          this.$notify({
            title: this.lange == "中文" ? "错误操作" : "Error",
            message:
              this.lange == "中文"
                ? "商品数量不能大于商品库存"
                : "The quantity of goods cannot exceed the inventory of goods",
            type: "error",
            duration: 0,
            offset: 100,
          });
          this.buyCountSum = this.buyCountSum - 1;
          this.$set(this.colorList[index], "fpeiBiCopy", item.fpeiBiCopy - 1);
        } else {
          this.infoCopy.price = this.infoCopy.commodityPriceList[
            this.findMaxIndex(this.infoCopy.commodityPriceList, this.buyCountSum)
          ].priceAfter;
          this.buyCountSumMoney = this.buyCountSum * this.infoCopy.price;
          this.ivolumeSum =
      ((this.buyCountSum / this.info.packingQuantity) * this.info.ivolume/ 1000000) ;
          console.log(this.ivolumeSum);
          this.buyCount = 0;
          console.log(this.fpeiBiSum);
          this.$forceUpdate();
        }
      }
      console.log(this.buyCountSum);
    },
    addCart() {
      if (!this.infoCopy.price) {
        this.$notify({
          title: this.lange == "中文" ? "错误操作" : "Error",
          message:
            this.lange == "中文"
              ? "无价格商品不允许加入购物车"
              : "Unpriced items are not allowed to be added to the shopping cart",
          type: "error",
          duration: 0,
          offset: 100,
        });
      } else if (this.infoCopy.availableQuantity == 0) {
        this.$notify({
          title: this.lange == "中文" ? "错误操作" : "Error",
          message:
            this.lange == "中文"
              ? "库存不足无法修改"
              : "Insufficient inventory cannot be modified",
          type: "error",
          duration: 0,
          offset: 100,
        });
      } else {
        getDcShoppingmallShoppingcart({ pageNum: 1, pageSize: 1000 }).then((res) => {
          this.cartListHave = res.data.filter((item) => {
            return (
              item.sellAttributeId == this.type && item.cinvCode == this.info.cinvCode
            );
          });
          if (this.cartListHave.length == 0) {
            if (this.lpButtonShow) {
              let shoppingCartDeatailList = [];
              this.colorList.forEach((item) => {
                shoppingCartDeatailList.push({
                  colorName: item.pcolorName,
                  peibiId: item.id,
                  colorQuantity: item.fpeiBiCopy,
                });
              });
              addDcShoppingmallShoppingcart({
                picePrice: this.infoCopy.price,
                totalPrice: this.buyCountSumMoney,
                quantity: this.buyCount,
                sellAttributeId: this.type,
                cinvCode: this.info.cinvCode,
                shoppingCartDeatailList: shoppingCartDeatailList,
                volume:  this.ivolumeSum.toFixed(2),
                boxNumber: this.buyCount,
              }).then((res) => {
                if (res.code == 200) {
                  this.dialogVisiblep = true;
                }
              });
            } else {
              let shoppingCartDeatailList = [];
              this.colorList.forEach((item) => {
                shoppingCartDeatailList.push({
                  colorName: item.pcolorName,
                  peibiId: item.id,
                  colorQuantity: item.fpeiBiCopy,
                });
              });
              addDcShoppingmallShoppingcart({
                picePrice: this.infoCopy.price,
                totalPrice: this.buyCountSumMoney,
                quantity: this.buyCount,
                sellAttributeId: this.type,
                cinvCode: this.info.cinvCode,
                shoppingCartDeatailList: shoppingCartDeatailList,
                volume: this.ivolumeSum.toFixed(2),
                boxNumber: this.buyCount,
              }).then((res) => {
                if (res.code == 200) {
                  this.dialogVisiblep = true;
                }
              });
            }
          } else {
            if (this.lpButtonShow) {
              let shoppingCartDeatailList = [];
              if (
                this.buyCountSum * 1 + this.cartListHave[0].quantity * 1 >
                this.infoCopy.availableQuantity
              ) {
                this.colorList.forEach((item) => {
                  this.cartListHave[0].shoppingCartDeatailList.forEach((items) => {
                    if (items.peibiId == item.id) {
                      shoppingCartDeatailList.push({
                        colorName: item.pcolorName,
                        peibiId: item.id,
                        colorQuantity: item.iquantity * 1,
                      });
                    }
                  });
                });
                editDcShoppingmallShoppingcart({
                  picePrice: this.infoCopy.price,
                  shoppingCartId: this.cartListHave[0].shoppingCartId,
                  totalPrice: (
                    this.infoCopy.availableQuantity * this.infoCopy.price.toFixed(2)
                  ).toFixed(2),
                  quantity: this.infoCopy.availableQuantity,
                  sellAttributeId: this.type,
                  cinvCode: this.info.cinvCode,
                  volume: (this.buyCountMax * this.infoCopy.ivolume) / 1000000,
                  boxNumber: this.buyCountMax,
                  shoppingCartDeatailList: shoppingCartDeatailList,
                }).then((res) => {
                  if (res.code == 200) {
                    this.dialogVisiblep = true;
                  }
                });
              } else {
                this.colorList.forEach((item) => {
                  this.cartListHave[0].shoppingCartDeatailList.forEach((items) => {
                    if (items.peibiId == item.id) {
                      shoppingCartDeatailList.push({
                        colorName: item.pcolorName,
                        peibiId: item.id,
                        colorQuantity: item.fpeiBiCopy * 1 + items.colorQuantity * 1,
                      });
                    }
                  });
                });
                editDcShoppingmallShoppingcart({
                  picePrice: this.infoCopy.price,
                  shoppingCartId: this.cartListHave[0].shoppingCartId,
                  totalPrice: (
                    this.buyCountSumMoney * 1 +
                    this.cartListHave[0].totalPrice * 1
                  ).toFixed(2),
                  quantity: this.buyCountSum * 1 + this.cartListHave[0].quantity * 1,
                  sellAttributeId: this.type,
                  cinvCode: this.info.cinvCode,
                  volume:
                    this.ivolumeSum.toFixed(2) * 1 + this.cartListHave[0].volume * 1,
                  boxNumber: this.buyCount * 1 + this.cartListHave[0].boxNumber * 1,
                  shoppingCartDeatailList: shoppingCartDeatailList,
                }).then((res) => {
                  if (res.code == 200) {
                    this.dialogVisiblep = true;
                  }
                });
              }
            } else {
              let shoppingCartDeatailList = [];
              if (
                this.buyCountSum * 1 + this.cartListHave[0].quantity * 1 >
                this.infoCopy.availableQuantity
              ) {
                this.colorList.forEach((item) => {
                  this.cartListHave[0].shoppingCartDeatailList.forEach((items) => {
                    if (items.peibiId == item.id) {
                      shoppingCartDeatailList.push({
                        colorName: item.pcolorName,
                        peibiId: item.id,
                        colorQuantity: item.iquantity * 1,
                      });
                    }
                  });
                });
                editDcShoppingmallShoppingcart({
                  picePrice: this.infoCopy.price,
                  shoppingCartId: this.cartListHave[0].shoppingCartId,
                  totalPrice: (
                    this.infoCopy.availableQuantity * this.infoCopy.price.toFixed(2)
                  ).toFixed(2),
                  quantity: this.infoCopy.availableQuantity,
                  sellAttributeId: this.type,
                  cinvCode: this.info.cinvCode,
                  volume: (this.buyCountMax * this.infoCopy.ivolume) / 1000000,
                  boxNumber: this.buyCountMax,
                  shoppingCartDeatailList: shoppingCartDeatailList,
                }).then((res) => {
                  if (res.code == 200) {
                    this.dialogVisiblep = true;
                  }
                });
              } else {
                this.colorList.forEach((item) => {
                  this.cartListHave[0].shoppingCartDeatailList.forEach((items) => {
                    if (items.peibiId == item.id) {
                      shoppingCartDeatailList.push({
                        colorName: item.pcolorName,
                        peibiId: item.id,
                        colorQuantity: item.fpeiBiCopy * 1 + items.colorQuantity * 1,
                      });
                    }
                  });
                });
                editDcShoppingmallShoppingcart({
                  picePrice: this.infoCopy.price,
                  shoppingCartId: this.cartListHave[0].shoppingCartId,
                  totalPrice: (
                    this.buyCountSumMoney * 1 +
                    this.cartListHave[0].totalPrice * 1
                  ).toFixed(2),
                  quantity: this.buyCountSum * 1 + this.cartListHave[0].quantity * 1,
                  sellAttributeId: this.type,
                  cinvCode: this.info.cinvCode,
                  volume:
                    this.ivolumeSum.toFixed(2) * 1 + this.cartListHave[0].volume * 1,
                  boxNumber: this.buyCount * 1 + this.cartListHave[0].boxNumber * 1,
                  shoppingCartDeatailList: shoppingCartDeatailList,
                }).then((res) => {
                  if (res.code == 200) {
                    this.dialogVisiblep = true;
                  }
                });
              }
            }
          }
        });
      }
    },
    addCarts() {
      this.dialogTableVisiblep = true;
    },
  },
};
</script>

<style lang="scss" scoped>
// .el-input-number {
//   width: 145px !important;
// }
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* 隐藏 Firefox 的数字输入箭头 */
input[type="number"] {
  -moz-appearance: textfield;
}
.counter1 {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border: 1px solid #8e8e8e;
  position: relative;
  line-height: 31px;
  border-radius: 40px;
}
.counters {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 85%;
  padding: 5px;
  border: 1px solid #8e8e8e;
  position: relative;
  line-height: 11px;
  border-radius: 40px;
}

// .counters span {
//   position: absolute;
//   left: 50%;
//   font-size: 15px;
//   top: 50%;
//   transform: translate(-50%, -50%);
// }

// .counters::before,
// .counters::after {
//   content: "";
//   cursor: pointer;
// }

// .counters::before {
//   content: "-";
//   font-size: 12px;
//   margin-left:4px;
// }

// .counters::after {
//   content: "+";
//   font-size: 12px;
//   margin-right: 4px;
// }
.counter {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border: 1px solid #8e8e8e;
  position: relative;
  line-height: 25px;
  border-radius: 40px;
}

.InfoWrap {
  width: 700px;

  .InfoName {
    font-size: 14px;
    line-height: 13px;
    margin-top: 10px;
  }

  .news {
    color: #3c3c3c;
    margin-top: 10px;
  }

  .priceArea {
    // background: #fff2e8;
    // padding: 7px;
    // margin: 13px 0;

    .priceArea1 {
      overflow: hidden;
      line-height: 28px;
      margin-top: 10px;
      justify-content: space-between;

      .title {
        margin-right: 15px;
      }

      .price {
        i,
        em {
          color: black;
        }

        i {
          font-size: 16px;
        }

        em {
          font-size: 24px;
          font-weight: 700;
          margin-left: 5px;
        }

        span {
          font-size: 12px;
        }
      }

      .remark {
        em,
        i {
          text-align: center;
          display: block;
          line-height: 13px;
          height: 15px;
        }
      }
    }

    .priceArea2 {
      overflow: hidden;
      line-height: 28px;
      margin-top: 10px;

      .title {
        margin-right: 15px;
        float: left;
      }

      .fixWidth {
        width: 520px;
        float: left;

        .red-bg {
          background: #f40;
          color: #fff;
          padding: 3px;
        }

        .t-gray {
          color: #999;
        }
      }
    }
  }

  .support {
    padding-bottom: 5px;

    .supportArea {
      overflow: hidden;
      line-height: 23px;
      margin-top: 10px;

      .title {
        margin-right: 15px;
        float: left;
      }

      .fixWidth {
        width: 520px;
        float: left;
        color: black;
        font-weight: 600;
      }
    }
  }

  .choose {
    .chooseArea {
      overflow: hidden;
      line-height: 28px;
      margin-top: 10px;

      dl {
        overflow: hidden;
        margin: 13px 0;

        dt {
          margin-right: 15px;
          float: left;
        }

        dd {
          float: left;
          margin-right: 5px;
          color: #666;
          line-height: 24px;
          padding: 2px 14px;
          border-top: 1px solid #eee;
          border-right: 1px solid #bbb;
          border-bottom: 1px solid #bbb;
          border-left: 1px solid #eee;

          &.active {
            color: green;
            border: 1px solid green;
          }
        }
      }
    }

    .cartWrap {
      .controls {
        position: relative;

        margin: 7px 0;

        .itxt {
          width: 38px;
          height: 34px;
          border: 1px solid #ddd;
          color: #555;
          border-right: 0;
          text-align: center;
          outline: none;
        }

        .plus,
        .mins {
          width: 27px;
          text-align: center;
          height: 38px;
          background: #f1f1f1;
          color: #666;
        }

        span {
          margin-right: 11px;
        }
      }

      .add {
        a {
          width: 180px;
          background-color: #f40;
          padding: 0 25px;
          font-size: 16px;
          color: #fff;
          height: 36px;
          line-height: 36px;
          text-align: center;
          display: inline-block;
          border-radius: 3px;
        }

        .buy {
          width: 134px;
          color: #e5511d;
          border-color: #f0cab6;
          background: #ffe4d0;
          margin-right: 20px;
          padding: 0;
        }
      }
    }
  }
}
</style>
