<template>
  <div>
    <el-dialog
      title="加入购物车"
      :visible.sync="outerVisible"
      width="40%"
      v-if="lange == '中文'"
      @close="offdialog"
    >
      <div class="detail">
        <div class="container">
          <div class="right" style="overflow: scroll">
            <!-- <div v-for="(item, index) in imageList" :key="index">
                <el-image
                  style="width: 100%"
                  :src="item.imageUrl"
                  :preview-src-list="[item.imageUrlList]"
                >
                </el-image>
              </div> -->
            <div class="previewWrap">
              <!--放大镜效果-->

              <Zoom
                :ImgSrc="imageList[imageIndex].imageUrl"
                :ImageIndex="imageIndex"
                @getIndexImage="imageChange"
              />
              <ImgList :ImgList="imageList" @getIndex="changeImage" />
              <!-- 小图列表 -->
            </div>
          </div>
          <div class="left" ref="leftContent">
            <div>
              <InfoDetail
                :info="currentItems"
                :type="goodType"
                :cInvCode="cInvCode"
                style="width: 100%"
                @backItem="offItem"
              ></InfoDetail>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="Add To Cart"
      :visible.sync="outerVisible"
      width="40%"
      @close="offdialog"
      v-else
    >
      <div class="detail">
        <div class="container">
          <div class="right" style="overflow: scroll">
            <!-- <div v-for="(item, index) in imageList" :key="index">
                <el-image
                  style="width: 100%"
                  :src="item.imageUrl"
                  :preview-src-list="[item.imageUrlList]"
                >
                </el-image>
              </div> -->
            <div class="previewWrap">
              <!--放大镜效果-->

              <Zoom :ImgSrc="imageList[imageIndex].imageUrl" />
              <ImgList :ImgList="imageList" @getIndex="changeImage" />
              <!-- 小图列表 -->
            </div>
          </div>
          <div class="left" ref="leftContent">
            <div>
              <InfoDetail
                :info="currentItems"
                :type="goodType"
                :cInvCode="cInvCode"
                @backItem="offItem"
                style="width: 100%"
              ></InfoDetail>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getDcBasearchiveCommodity,
  getRecommendCommodityPage,
  getDcBasearchiveCommodityFile,
  hostGood,
} from "@/api/good/index.js";
import imageSwiper from "@/components/imageSwiper/index.vue";
import shopOrder from "../shopOrder/index.vue";
import Zoom from "../Detail/zoomAddCartDialog.vue";
import ImgList from "../Detail/imgListDialog.vue";
import InfoDetail from "../Detail/infoDerailDialog.vue";
import InfoDetailOther from "../Detail/infoDetailOther.vue";
import { mapGetters } from "vuex";
export default {
  name: "DetailPrd",
  computed: {
    ...mapGetters({
      lange: "user/lange",
    }),
  },
  data() {
    return {
      isHome: false,
      skuId: undefined,
      dialogTableVisible: false,
      skuNum: 1,
      dialogImageUrl: "",
      currentItems: {},
      scrollHeight: 300,
      imageList: [],
      currentItem: {},
      hotgoodsList: [],
      imageIndex: 0,
      goodType: 1,
      outerVisible: false,
      cInvCode: "",
    };
  },
  // computed: {
  //     ...mapState(['currentItem'])
  // },
  mounted() {},
  created() {},
  components: {
    Zoom,
    ImgList,
    InfoDetail,
    InfoDetailOther,
    shopOrder,
    imageSwiper,
  },
  methods: {
    imageChange(index) {
      console.log(index);
      if (index >= this.imageList.length - 1) {
        this.imageIndex = 0;
      } else {
        this.imageIndex = index * 1 + 1 * 1;
      }
    },
    offdialog() {
      this.$emit("back");
    },
    offItem() {
      this.outerVisible = false;
      console.log(2222);
    },
    handleScrollp() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > 150) {
        this.$refs.leftContent.style.position = "sticky";
        this.$refs.leftContent.style.top = 0;
      }
    },
    buyGoods(item) {
      window.open(
        this.$locationViewUrl + "#/goods/Detail?cinvCode=" + item.cinvCode+'&brandName='+this.brandName,
        "_blank"
      );
    },
    show(row) {
      console.log(row);
      this.cInvCode = row;
      getDcBasearchiveCommodity({
        cInvCode: row,
        pageNum: 1,
        pageSize: 10,
      }).then((res) => {
        if (res.code == 200) {
          this.currentItems = res.data.list[0];
          this.goodType = 1;
          getDcBasearchiveCommodityFile({
            cInvCode: row,
            pageNum: 1,
            pageSize: 10,
            fileType: 3,
          }).then((res) => {
            this.imageList = res.data;
            this.imageList.forEach((item) => {
              item.imageUrl = this.$locationUrl + "file/" + item.fileurl;
              item.imageUrlList = [item.imageUrl];
              console.log(item.imageUrl);
            });
            this.outerVisible = true;
            console.log(this.imageList);

            this.$forceUpdate();
          });
        }
      });
    },
    dialogVisibleClick(item) {
      this.dialogVisible = true;
      this.dialogImageUrl = item;
    },
    changeImage(v) {
      console.log(v);
      let that = this;
      this.$nextTick(() => {
        that.imageIndex = v;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.new_goods_title_hot {
  height: 40px;

  margin-top: 140px;
  margin-bottom: 60px;

  span {
    font-size: 40px;
    line-height: 28px;

    padding-left: 15px;
    font-weight: bold;
    font-size: 32px;
  }
}
.recommend {
  margin-top: 20px;

  .item {
    // height: 510px;
    margin-left: 20px;
    margin-bottom: 12px;
    width: 21%;

    .recommend_left {
      height: 280px;
      width: 360px;

      img {
        width: 400px;
        height: 320px;
      }
    }

    &:nth-child(1),
    &:nth-child(4n + 1) {
      margin-left: 0;
    }

    dl {
      width: 100%;
      // border: 1px solid #eee;

      img {
        width: 100%;
        // height: 400px;
        // &:hover {
        //   width: 440px;
        //   width: 440px;
        // }
      }

      dd {
        padding: 0 5px;
      }

      .title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 15px;
        font-weight: 700;
        color: black;
        line-height: 28px;
      }

      .other {
        .price {
          font-size: 17px;
          color: black;
          font-weight: 600;
        }

        .wei {
          font-size: 14px;
          color: #666;
        }
      }

      .btn {
        line-height: 28px;
        text-align: center;
        color: black;
        border: 1px solid black;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 30px;
        /* margin: 6px auto 10px auto; */
        display: block;
        line-height: 20px;
        font-size: 12px;
        font-weight: 700;
        padding: 2px 10px;
        &:hover {
          color: #fff;
          background: black;
        }
      }
      .btns {
        line-height: 28px;
        text-align: center;
        color: black;
        border: 1px solid black;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 30px;
        /* margin: 6px auto 10px auto; */
        display: block;
        line-height: 20px;
        font-size: 12px;
        font-weight: 700;
        padding: 2px 10px;
        &:hover {
          color: #fff;
          background: black;
        }
      }
    }
  }

  .null {
    width: 188px;
    border: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.left {
  /* 设置左侧模块高度为视口高度 */
  /* 设置左侧模块宽度 */
  display: flex;
}
.right {
  top: 0;
  flex: 1;

  /* 设置右侧模块高度，使其能够滚动 */
}
.left::-webkit-scrollbar {
  display: none;
}

/* 隐藏 IE 和 Edge 浏览器滚动条 */
.left {
  -ms-overflow-style: none;
}
.right::-webkit-scrollbar {
  display: none;
}

/* 隐藏 IE 和 Edge 浏览器滚动条 */
.right {
  -ms-overflow-style: none;
}
.tb-item-info {
  padding: 20px;
  position: relative;
  box-sizing: border-box;
  border: 1px solid #e8e8e8;
  justify-content: space-between;
}

.mask {
  z-index: 10;
  top: -8px;
  right: 15px;
  width: 30px;
  height: 21px;
  text-align: center;
  background-color: #fff;
}

.section4 {
  position: relative;
  text-align: center;
  margin-bottom: 25px;
  background: #fff;

  & > span {
    position: relative;
    display: inline-block;
    margin: 25px auto;
    font-weight: 700;
    font-size: 22px;

    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      display: inline-block;
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(rgba(255, 166, 0, 0.929412)),
        to(#ff6a00)
      );
      background: linear-gradient(90deg, rgba(255, 166, 0, 0.929412), #ff6a00);
      width: 40%;
      border-radius: 50px;
      height: 4px;
    }
  }

  .article_list {
    text-align: left;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-wrap: wrap;

    .article_item {
      border: 1px solid #eee;
      padding: 10px 30px;
      margin: 10px 0;
      width: 44%;
      cursor: pointer;

      .title {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        margin-bottom: 10px;
      }

      .summary {
        color: #767676;
        font-size: 14px;
      }

      .title a {
        font-size: 18px;
        color: #333;
      }

      .title {
        p {
          color: #999;
        }
      }
    }
  }
}

.table-header {
  width: 92%;
  margin-left: 6%;
  margin-right: 2%;
  height: 50px;
  line-height: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-family: SourceHanSansCN-Medium;
  color: #092553;
  font-weight: 900;
  font-size: 19px;
}

.table-header-title {
  border-radius: 2.5px;
  width: 5px;
  height: 16px;
  margin-right: 15px;
  background-color: #28bd9c;
}

.billList {
  padding: 20px;

  .item {
    margin-bottom: 25px;

    .name {
      padding: 15px 0;
      font-weight: bold;
      text-align: center;
      border: 1px solid #e8e8e8;
      font-size: 30px;
    }

    .content {
      font-size: 16px;

      .items {
        display: flex;
        // border-left: 1px solid #e8e8e8;

        .cell {
          width: 355px;
          display: flex;

          .title {
            width: 150px;
            padding: 15px 0;
            text-align: center;
            // border-right: 1px solid #e8e8e8;
            // border-bottom: 1px solid #e8e8e8;
            font-weight: bold;
          }

          .msg {
            width: 205px;
            text-align: center;
            padding: 15px 0;
            // border-right: 1px solid #e8e8e8;
            border-bottom: 1px solid #8b8b8b;
          }
        }
      }
    }
  }
}
</style>
